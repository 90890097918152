<template>
  <div :class="`happy-liver ${getCurrentLangs.lang}`" data-view>
    <Header/>
    <scrollAnalytics></scrollAnalytics>

    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('happyLiver.head')"></div>

        <router-link to="/cases" class="top__back-btn cursor-hover">
          <div class="top__arrow">
            <svg
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071"
                stroke="black"
                stroke-linecap="round"
              />
              <path
                d="M15.2988 29.973L38.2398 29.9732"
                stroke="black"
                stroke-linecap="round"
              />
            </svg>
          </div>

          {{ $t("back-btn") }}
        </router-link>
      </div>

      <h1 class="top__title animation iosTitle type1">
        <span class="text-wrapper">
          <span class="letters" v-html="$t('happyLiver.title')"></span>
        </span>
        <br/>
        <span
          class="text-wrapper"
          v-for="(key, value) in $t('happyLiver.subtitle')"
          :key="value"
        >
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class="banner">
      <picture>
        <source
          srcset="@/assets/img/cases/happy-liver/banner-1-mob.png"
          media="(max-width: 425px)"
        />
        <source
          srcset="@/assets/img/cases/happy-liver/banner-1-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          v-parallax.modifier.absY="0.04"
          src="@/assets/img/cases/happy-liver/banner-1.jpg"
          alt=""
        />
      </picture>
    </section>

    <section class="challenge">
      <div class="challenge__content-col">
        <div class="challenge__title-col">
          <p
            class="challenge__title"
            v-html="$t('happyLiver.challenge.title')"
          ></p>
        </div>
        <div class="challenge__text-col">
          <p
            class="challenge__subtitle"
            v-html="$t('happyLiver.challenge.subtitle')"
          ></p>
          <p
            class="challenge__text"
            v-html="$t('happyLiver.challenge.text')"
          ></p>
        </div>
      </div>
      <div class="challenge__image-col">
        <img
          class="challenge__hexagon"
          src="@/assets/img/cases/happy-liver/hexagon.png"
          alt="hexagon"
        />
        <img
          class="challenge__image"
          src="@/assets/img/cases/happy-liver/livers.gif"
          alt="livers"
        />
      </div>
    </section>

    <section class="idea">
      <div class="idea__title-col">
        <p class="idea__title" v-html="$t('happyLiver.idea.title')"></p>
      </div>
      <div class="idea__text-col">
        <p class="idea__subtitle" v-html="$t('happyLiver.idea.subtitle')"></p>
      </div>
    </section>

    <section class="banner banner--2">
      <picture>
        <source
          srcset="@/assets/img/cases/happy-liver/banner-2-mob.png"
          media="(max-width: 425px)"
        />
        <source
          srcset="@/assets/img/cases/happy-liver/banner-2-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          v-parallax.modifier.absY="0.04"
          src="@/assets/img/cases/happy-liver/banner-2.png"
          alt=""
        />
      </picture>
    </section>

    <section class="execution">
      <div class="execution__double-block">
        <div class="execution__left">
          <p
            class="execution__left-text"
            v-html="$t('happyLiver.execution.left-text')"
          ></p>
        </div>
        <div class="execution__right">
          <p
            class="execution__right-title"
            v-html="$t('happyLiver.execution.right-title')"
          ></p>
          <p
            class="execution__right-text"
            v-html="$t('happyLiver.execution.right-text')"
          ></p>
          <p
            class="execution__right-text"
            v-html="$t('happyLiver.execution.right-text2')"
          ></p>
        </div>
      </div>
    </section>

    <section class="banner banner--3">
      <picture>
        <source
          srcset="@/assets/img/cases/happy-liver/banner-3-mob.png"
          media="(max-width: 425px)"
        />
        <source
          srcset="@/assets/img/cases/happy-liver/banner-3-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          v-parallax.modifier.absY="0.04"
          src="@/assets/img/cases/happy-liver/banner-3.png"
          alt=""
        />
      </picture>
    </section>

    <section class="interview">
      <swiper
        ref="interviewSlider"
        :options="swiperInterviewOptions"
        class="interview__slider"
      >
        <swiper-slide
          v-for="(val, index) in 8"
          :key="index"
          class="interview__slider"
        >
          <img
            :src="
              require(`@/assets/img/cases/happy-liver/swiper/img-${val}.png`)
            "
            alt="interview"
          />
        </swiper-slide>
        <div class="swiper-interview-pagination" slot="pagination"></div>
      </swiper>
      <div class="pagination__wrapper">
        <svg
          class="
            left
            swiper-interview-button swiper-interview-button-prev
            cursor-hover
          "
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.2539 19.7256L15.5665 30.4133L26.2635 41.1104"
            stroke-linecap="round"
          />
          <path d="M16.016 30.3763L38.957 30.3765" stroke-linecap="round"/>
        </svg>
        <svg
          class="
            right
            swiper-interview-button swiper-interview-button-next
            cursor-hover
          "
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.7461 19.7256L42.4335 30.4133L31.7365 41.1104"
            stroke-linecap="round"
          />
          <path d="M41.984 30.3763L19.043 30.3765" stroke-linecap="round"/>
        </svg>
      </div>
    </section>

    <section class="banner banner--4">
      <picture>
        <source
          srcset="@/assets/img/cases/happy-liver/banner-4-mob.png"
          media="(max-width: 425px)"
        />
        <source
          srcset="@/assets/img/cases/happy-liver/banner-4-tablet.png"
          media="(max-width: 768px)"
        />
        <img
          v-parallax.modifier.absY="0.04"
          src="@/assets/img/cases/happy-liver/banner-4.png"
          alt=""
        />
      </picture>
    </section>

    <section class="materials">
      <div class="materials__double-block">
        <div class="materials__left">
          <p
            class="materials__left-text"
            v-html="$t('happyLiver.materials.left-text')"
          ></p>
        </div>
        <div class="materials__right">
          <p
            class="materials__right-title"
            v-html="$t('happyLiver.materials.right-title')"
          ></p>
          <p
            class="materials__right-text"
            v-html="$t('happyLiver.materials.right-text')"
          ></p>
        </div>
      </div>
    </section>

    <section class="videos">
      <div class="videos__overflow">
        <swiper
          ref="videosSlider"
          :options="swiperVideosOptions"
          class="videos__slider"
          @slideChange="slideChange"
        >
          <swiper-slide
            v-for="(val, index) in 3"
            :key="index"
            class="videos__slider--slide"
          >
            <div class="videos__video-wrapper cursor-hover">
              <div
                class="videos__play-btn cursor-hover"
                @click="playSwiperVideos(`videos-${val}`)"
                v-bind:class="[{ active: videosTrigger !== `videos-${val}` }]"
              ></div>

              <video
                v-if="getCurrentLangs.lang === 'en'"
                @click="playSwiperVideos(`videos-${val}`)"
                :ref="`videos-${val}`"
                :poster="
                  require(`@/assets/img/cases/happy-liver/happy_liver_preroll_poster-${val}-en.jpg`)
                "
              >
                <source
                  :src="
                    require(`@/assets/video/happy-liver/happy_liver_preroll-${val}-en.mp4`)
                  "
                  type="video/mp4"
                />
              </video>
              <video
                v-else
                @click="playSwiperVideos(`videos-${val}`)"
                :ref="`videos-${val}`"
                :poster="
                  require(`@/assets/img/cases/happy-liver/happy_liver_preroll_poster-${val}-ua.jpg`)
                "
              >
                <source
                  :src="
                    require(`@/assets/video/happy-liver/happy_liver_preroll-${val}-ua.mp4`)
                  "
                  type="video/mp4"
                />
              </video>
            </div>
          </swiper-slide>

          <div class="swiper-videos-pagination" slot="pagination"></div>
        </swiper>
        <div class="pagination__wrapper">
          <svg
            class="
              left
              swiper-videos-button swiper-videos-button-prev
              cursor-hover
            "
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.2539 19.7256L15.5665 30.4133L26.2635 41.1104"
              stroke-linecap="round"
            />
            <path d="M16.016 30.3763L38.957 30.3765" stroke-linecap="round"/>
          </svg>
          <svg
            class="
              right
              swiper-videos-button swiper-videos-button-next
              cursor-hover
            "
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.7461 19.7256L42.4335 30.4133L31.7365 41.1104"
              stroke-linecap="round"
            />
            <path d="M41.984 30.3763L19.043 30.3765" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </section>

    <section class="video">
      <div class="video__video-wrapper cursor-hover">
        <div
          class="video__play-btn cursor-hover"
          @click="playVideo"
          v-bind:class="[{ active: !videoTrigger }]"
        ></div>

        <video
          v-if="getCurrentLangs.lang === 'en'"
          @click="playVideo"
          ref="video"
          poster="@/assets/img/cases/happy-liver/shcherdryk-poster-en.jpg"
        >
          <source
            src="@/assets/video/happy-liver/happy_liver_shcherdryk_en.mp4"
            type="video/mp4"
          />
        </video>
        <video
          v-else
          @click="playVideo"
          ref="video"
          poster="@/assets/img/cases/happy-liver/shcherdryk-poster-ua.jpg"
        >
          <source
            src="@/assets/video/happy-liver/happy_liver_shcherdryk_ua.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </section>

    <section class="results">
      <div class="results__double-block">
        <div class="results__left">
          <p
            class="results__left-title"
            v-html="$t('happyLiver.results.left-title')"
          ></p>
        </div>
        <div class="results__right">
          <p
            class="results__right-title type1"
            v-html="$t('happyLiver.results.right-title')"
          ></p>
          <p
            class="results__right-title type2"
            v-html="$t('happyLiver.results.right-title2')"
          ></p>
          <p
            class="results__right-text"
            v-html="$t('happyLiver.results.right-text2')"
          ></p>
        </div>
      </div>
    </section>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import similar from "@/components/similar.vue";
import Footer from "@/components/NewFooter.vue";
import anime from "animejs";
import {mapGetters, mapMutations} from "vuex";
// import getElementVisible from '@/helper';

export default {
  name: "HappyLiver",
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      videoTrigger: false,
      currentMediaVideo: null,
      videosTrigger: null,
      currentSwiperVideo: null,
      similarData: {
        title: "similar-title-type1",
        prevLink: "/cases/vacciland",
        nextLink: "/cases/periods",
        linkShow: true,
        slider: [
          "slider-no-spa",
          "slider-allegra",
          "slider-listerin",
          "slider-hepatoconstructor",
        ],
      },
      swiperInterviewOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-interview-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-interview-button-next",
          prevEl: ".swiper-interview-button-prev",
        },
      },
      swiperVideosOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-videos-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-videos-button-next",
          prevEl: ".swiper-videos-button-prev",
        },
      },
    };
  },

  mounted() {
    this.setCurrentPage("cases");

    setTimeout(() => {
      const whySection = document.querySelector(".top");
      const textWrapperAll = whySection.querySelectorAll(
        ".text-wrapper .letters"
      );
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes("®")) {
          textWrapper.innerHTML = textWrapper.textContent
            .replace(/\S/g, "<span class='letter'>$&</span>")
            .replace("®", "<sup>®</sup>");
        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(
            /\S/g,
            "<span class='letter'>$&</span>"
          );
        }

        anime.timeline({loop: false}).add({
          targets: ".text-wrapper .letter",
          translateY: ["1.2em", 0],
          translateZ: 0,
          duration: 750,
          delay: (el, i) => 50 * i,
        });
      });
    }, 20);
  },

  methods: {
    ...mapMutations(["setCurrentPage"]),

    playVideo() {
      const mediaVideo = this.$refs.video;

      if (mediaVideo.paused) {
        this.stopSwiperVideos();

        mediaVideo.play();
        this.videoTrigger = true;
        this.currentMediaVideo = mediaVideo;
        gtag('event', 'scroll', {'event_category': 'play single video', 'event_label': this.$route.path})

      } else {
        mediaVideo.pause();
        this.videoTrigger = false;
        this.currentMediaVideo = null;
      }
    },
    playSwiperVideos(ref) {
      const swiperVideo = this.$refs[ref][0];

      if (swiperVideo.paused) {
        this.stopSwiperVideos();
        if (this.currentMediaVideo) this.stopMediaVideo();

        swiperVideo.play();
        this.videosTrigger = ref;
        this.currentSwiperVideo = swiperVideo;

        gtag('event', 'scroll', {'event_category': 'play carousel video: ' + ref, 'event_label': this.$route.path})

      } else {
        swiperVideo.pause();
        this.videosTrigger = null;
        this.currentSwiperVideo = null;
      }
    },
    stopSwiperVideos() {
      if (this.currentSwiperVideo) {
        const swiperVideo = this.$refs[this.videosTrigger][0];
        swiperVideo.currentTime = 0;

        this.currentSwiperVideo.pause();
        this.videosTrigger = null;
        this.currentSwiperVideo = null;
      }
    },
    slideChange() {
      this.stopSwiperVideos();
    },
    stopMediaVideo() {
      this.currentMediaVideo.pause();
      this.videoTrigger = null;
      this.currentMediaVideo = null;
    },
  },

  computed: {
    ...mapGetters(["getCurrentLangs"]),
    swiper() {
      return this.$refs.videosSlider.$swiper;
    },
  },
};
</script>

<style scoped lang="scss">
.dark-theme {
  & .happy-liver .challenge__hexagon {
    opacity: 1;
  }
}

.happy-liver {
  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  &.ua {
    .top__title {
      font-size: 80px;

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }
      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .top {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 62px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 38px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 10px 20px 26px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }

    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all 0.2s ease;

      @media screen and (min-width: 1023px) {
        &:hover {
          svg {
            path {
              stroke: #29f6d9;
            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29f6d9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin: 0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all 0.2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 108px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;

      position: relative;

      .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: 0.9em;
      }

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }

      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .banner {
    width: 100%;
    overflow: hidden;

    &--2 {
      padding-bottom: 20px;

      @media screen and (max-width: 768px) {
        padding-bottom: 10px;
      }
      @media screen and (max-width: 425px) {
        padding-bottom: 5px;
      }
    }

    &--3 {
      padding-top: 54px;
      padding-bottom: 40px;

      @media screen and (max-width: 768px) {
        padding-top: 39px;
        padding-bottom: 47px;
      }
      @media screen and (max-width: 425px) {
        padding-top: 36px;
      }
    }

    &--4 {
      padding-top: 100px;
      padding-bottom: 20px;

      @media screen and (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 10px;
      }
      @media screen and (max-width: 425px) {
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .challenge {
    max-width: calc(1140px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 46px 45px 0 45px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      padding: 56px 45px 0 45px;
      flex-direction: column;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 0 20px;
    }

    &__content-col {
      max-width: 540px;
      width: 100%;
      padding-right: 5px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        flex-direction: row;
        max-width: 100%;
        padding-right: 0;
        margin-bottom: 75px;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-bottom: 58px;
      }
    }

    &__title-col {
      margin-bottom: 64px;

      @media screen and (max-width: 768px) {
        flex: 0 0 235px;
      }
      @media screen and (max-width: 600px) {
        flex: 0 0 auto;
        margin-bottom: 24px;
      }
    }

    &__title {
      font-size: 16px;
      line-height: 1.8em;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__subtitle {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 23px;

      @media screen and (max-width: 768px) {
        margin-bottom: 16px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    &__text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
    }

    &__image-col {
      position: relative;
      max-width: 600px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__hexagon {
      display: block;
      max-width: 366px;
      width: 100%;
      height: auto;
      opacity: 0.2;

      @media screen and (max-width: 425px) {
        width: 220px;
      }
    }

    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: block;
      max-width: 600px;
      width: 100%;
      height: auto;
    }
  }

  .idea {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 111px 45px 96px 45px;

    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      padding: 102px 45px 48px 45px;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      padding: 59px 20px 40px 20px;
    }

    &__title-col {
      margin-bottom: 64px;
      max-width: 378px;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-bottom: 24px;
      }
    }

    &__text-col {
      padding-right: 50px;

      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }

    &__title {
      font-size: 16px;
      line-height: 1.8em;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__subtitle {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    &__text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
    }
  }

  .execution {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 0px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 0px 44px 0 44px;
    }
    @media screen and (max-width: 425px) {
      padding: 0px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 38px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 32px;

      @media screen and (max-width: 425px) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .interview {
    padding: 0 44px 10px 44px;
    position: relative;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      padding: 0 44px 10px 44px;
      margin-bottom: 9px;
    }
    @media screen and (max-width: 425px) {
      padding: 0 20px 10px 20px;
      margin-bottom: 6px;
    }

    ::v-deep .swiper-interview-button {
      margin-top: 0;
      position: relative;
      width: auto;
      height: auto;
      color: #000;
      stroke: var(--text-primary-color);

      &:hover,
      &:active {
        &:not(.swiper-button-disabled) {
          stroke: #29f6d9;
        }
      }
    }

    ::v-deep .swiper-button-disabled {
      stroke: var(--service-border-color);
    }

    ::v-deep .swiper-interview-pagination {
      bottom: 0;
      color: #d4dadf;
      position: relative;
      margin-top: 36px;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #92a1ae;
    }

    ::v-deep .swiper-pagination-current {
      font-family: "TimesNewRoman-I";
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-style: italic;
      color: var(--text-primary-color);
      letter-spacing: 0.04em;
    }

    .pagination__wrapper {
      z-index: 1;
      position: absolute;
      left: 50%;
      bottom: -6px;
      transform: translateX(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        margin-right: 60px;
      }
    }

    &__slider {
      max-width: calc(1110px + 90px);
      margin: 0 auto;
    }
  }

  .materials {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 50px 45px 94px 45px;

    @media screen and (max-width: 768px) {
      padding: 30px 45px 48px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 20px 20px 36px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 40px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
    }
  }

  .videos {
    padding: 0 44px 10px 44px;
    max-width: calc(1110px + 90px);
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 425px) {
      padding: 0 20px 10px 20px;
    }

    &__overflow {
      overflow: hidden;
    }

    ::v-deep .swiper-container {
      overflow: visible;
    }

    ::v-deep .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0.5;
      }
    }

    ::v-deep .swiper-videos-button {
      margin-top: 0;
      position: relative;
      width: auto;
      height: auto;
      color: #000;
      stroke: var(--text-primary-color);

      &:hover,
      &:active {
        &:not(.swiper-button-disabled) {
          stroke: #29f6d9;
        }
      }
    }

    ::v-deep .swiper-button-disabled {
      stroke: var(--service-border-color);
    }

    ::v-deep .swiper-videos-pagination {
      bottom: 0;
      color: #d4dadf;
      position: relative;
      margin-top: 36px;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #92a1ae;
    }

    ::v-deep .swiper-pagination-current {
      font-family: "TimesNewRoman-I";
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-style: italic;
      color: var(--text-primary-color);
      letter-spacing: 0.04em;
    }

    .pagination__wrapper {
      z-index: 1;
      position: absolute;
      left: 50%;
      bottom: -6px;
      transform: translateX(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        margin-right: 60px;
      }
    }

    &__video-wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }

  .video {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 52px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 48px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 36px 20px 0 20px;
    }

    &__video-wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }

  .results {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 117px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 104px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 36px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);

      text-align: left;

      @media screen and (max-width: 768px) {
        margin-bottom: 24px;
        font-size: 12px;
      }
    }

    &__right {
      max-width: 66%;
      width: 100%;
      margin-left: 15px;

      @media screen and (max-width: 600px) {
        max-width: 100%;
        margin-left: 0;
      }
    }

    &__right-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;

      color: var(--text-primary-color-case);
      margin-bottom: 16px;
      text-align: left;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }

      &.type2 {
        font-weight: 400;
        font-size: 64px;
        line-height: 88px;

        @media screen and (max-width: 768px) {
          font-size: 48px;
          line-height: 36px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 425px) {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 8px;
        }
      }
    }

    &__right-text {
      text-align: left;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      max-width: 543px;

      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
</style>
